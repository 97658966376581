/* @import 'variables'; */

:root {
    --primary1: #FBFFF1;
    --primary2: #AF7A6D;
    --primary3: #B4C5E4;
    --secondary: #05204A;
    --accent1: #FFB300;
    --accent2: #D62839;

    --bs-body-font-family: 'Exo', sans-serif;
}

.primary1 {
    color: var(--secondary);
    background-color: var(--primary1);
}

.primary2 {
    color: var(--primary1);
    background-color: var(--primary2);
}

.primary3 {
    color: var(--secondary);
    background-color: var(--primary3);
}

.secondary {
    color: var(--primary1);
    background-color: var(--secondary);
}

.accent1 {
    color: var(--secondary);
    background-color: var(--accent1);
}

.accent2 {
    color: var(--primary1);
    background-color: var(--accent2);
}

.serif {
    font-family: 'Alice', serif;
}

.mybutton {
    width: 100px;
}

#btn-email-list {
    width: 200px;
}

.card-carousel {
    height: 300px;
}

.car-item {
    height: 250px;
}

.footer {
    /* position: fixed; */
    bottom: 0;
    width: 100%;
}

@media (min-width: 10px) {
    .btn-icon {
        margin: 1px;
    }

    #img-coffee {
        width: 150px;
    }

    #img-kofi {
        width: 150px;
    }
}

@media (min-width: 580px) {
    .btn-icon {
        margin: 1px 5px;
    }
}

@media (min-width: 780px) {
    .btn-icon {
        margin: 1px 8px;
    }

    #img-coffee {
        width: 200px;
    }

    #img-kofi {
        width: 300px;
    }
}

@media (min-width: 980px) {
    .btn-icon {
        margin: 1px 10px;
    }
}

@media (min-width: 1280px) {
    .btn-icon {
        margin: 1px 15px;
    }
}

@media (min-width: 1480px) {
    .btn-icon {
        margin: 1px 18px;
    }
}

@media (min-width: 1780px) {
    .btn-icon {
        margin: 1px 20px;
    }
}